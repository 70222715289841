























import { TYPE_TEXT } from "@/constant";
import { Toast } from "vant";
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component({
  components: {
  },
})
export default class RecyleCard extends Vue {
    @Prop() item:any
    @Prop() full:any
    status = false
    created(){
    }
    get CharacterStyle(): Record<string, string> {
    const { character } = this.item;
    return {
      "--characterColor": `var(--card-color-${character.value})`,
    };
  }
    get characterText(): string {
        const { character } = this.item;
        return TYPE_TEXT[character.value] ?? "";
    }
    get RecyleWidth(){
        const {clientWidth} = document.body
        return `width:${(clientWidth - 42)/3}px;height:${(clientWidth - 42)/3}px`
    }
    get ContentWidth(){
        const {clientWidth} = document.body
        return `width:${(clientWidth - 42)/3}px`
    }
    count = 0
    @Emit()
    check(){
        if(this.full&&!this.status){
          Toast('最多只能选择8个')
          return 
        }
        this.status = !this.status
        return this.item.id
    }
}
