



















































import { Component, Vue } from "vue-property-decorator";
import { List, Toast } from "vant";
import RecyleCard from "@/views/recyle/components/RecyleCard.vue";
import BlindBox from "@/api/blind-box.api";
import EmptyBox from "@/components/EmptyBox.vue";
import InteractionLib from "@/utils/interaction.lib";
@Component({
  components: {
    RecyleCard,
    EmptyBox,
    List,
  },
})
export default class Recyle extends Vue {
  supply: any = [];
  full = false
  chooseList: any[number] = [];
  page = 0;
  page_size = 10;
  finished = false;
  loading = false;
  tip = "从标识支持回收的补给箱中获得得补给才会出现在这里哦～";
  created() {
    this.load();
  }
  async load() {
    this.page++;
    let res: any = await BlindBox.getMyRecyleSupply(this.page, this.page_size);
    this.loading = false;
    if (res.length < 10) {
      this.finished = true;
    }
    res.forEach((item: any) => {
      this.supply.push(item);
    });
  }
  check(item: number) {
    console.log()
    if (this.chooseList.length > 0) {
      let status = this.chooseList.includes(item);
      let arr: any[] = [];
      if (status) {
        this.chooseList.forEach((res: number) => {
          if (res != item) {
            arr.push(res);
          }
        });
        this.chooseList = arr;
        console.log(JSON.stringify(this.chooseList),'11111')
        this.full = false
      } else {
       if(!this.full){
          this.chooseList.push(item);
       }
        console.log(JSON.stringify(this.chooseList),'22222')

        if(this.chooseList.length>7){
        this.full = true
      }
        
      }
    } else {
      this.chooseList.push(item);
    }
  }
  nowList: any = [];
  toRecyle() {
    this.nowList = [];
    if (this.chooseList.length > 0) {
      this.chooseList.forEach((res: any) => {
        let index = this.supply.findIndex((item: any) => res == item.id);
        this.nowList.push({
          id: this.supply[index].id,
          supply_type: this.supply[index].supply_type,
        });
      });
    } else {
      return;
    }
    if (InteractionLib.isApp()) {

      return InteractionLib.openWebView(
        `${location.origin}/recyle-detail/?nowList=${JSON.stringify(this.nowList)}`
      );
    }

    this.$router.push({
      name: "recyleDetail",
      query: {
        nowList: JSON.stringify(this.nowList),
      },
    });
  }
}
